<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/template/echarts' }">列表</el-breadcrumb-item>
                <el-breadcrumb-item>数据统计图</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->

                        <div id="main" style="width: 600px;height:400px;"></div>

        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    components: {

    },
    data() {
        return {
            page_name: 'ECharts',
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
        this.drawLine();
    },
    methods: {
        drawLine () {

            var myChart = echarts.init(document.getElementById('main'));
            var data = [];
            for (let i = 0; i < 5; ++i) {
                data.push(Math.round(Math.random() * 200));
            }

            myChart.setOption({
                title: {
                    text: '圆环图的例子',
                },
                xAxis: {
                    data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                },
                yAxis: {},
                series: [
                    {
                        type: 'scatter',
                        data: [220, 182, 191, 234, 290, 330, 310]
                    }
                ]
            });

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
